import React from "react";
import { Typography, Button, Box } from "@mui/material";
import WifiOffIcon from "@mui/icons-material/WifiOff";

const NetworkLoss = ({ onRetry }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          maxWidth: { md: "28rem" },
          margin: "20px auto",
          padding: 2,
          textAlign: "center",
          backgroundColor: "white",
          border: { md: "1px solid lightgray" },
          borderRadius: 4,
          boxShadow: { md: 1 },
        }}
      >
        <Box>
          <WifiOffIcon sx={{ color: "#1A73E8", fontSize: "70px" }} />
        </Box>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            color: "black",
            marginBottom: 2,
            fontFamily: "circular",
            fontSize: "30px",
            fontWeight: "600",
          }}
        >
          Lost Connection
        </Typography>
        <Typography
          variant="body2"
          component="p"
          sx={{
            color: "black",
            marginBottom: 3,
            fontFamily: "circular",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          Oops! No internet connection found. Check your connection.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => { window.location.reload()}}
          sx={{
            textTransform: "none",
            fontFamily: "circular",
            fontSize: ".875rem;",
            borderRadius: "8px",
            boxShadow: "none",
          }}
        >
          Try again!
        </Button>
      </Box>
    </Box>
  );
};

export default NetworkLoss;
