
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    signature: null,
    meetingId: null,
    meetingPasscode: null,
    name: "",
    email: null,
};

// Create the slice
const meetingSlice = createSlice({
    name: 'meeting',
    initialState,
    reducers: {
        // Action to set the webinar details
        setMeetingDetails(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        },
        // Action to clear the webinar details
        clearMeetingDetails(state) {
            return initialState;
        },
    },
});

// Export actions
const meetingReducer = meetingSlice.reducer;

const meetingActions = meetingSlice.actions;
export { meetingReducer, meetingActions };