// src/slices/webinarSlice.js
import { createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState = {
    signature: null,
    webinarId: null,
    name: null,
    email: null,
};

const webinarSlice = createSlice({
    name: 'webinar',
    initialState,
    reducers: {
        setWebinarDetails(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        },
        clearWebinarDetails(state) {
            return initialState;
        },
    },
});


const webinarReducer = webinarSlice.reducer;

const webinarActions = webinarSlice.actions;
export { webinarReducer, webinarActions };