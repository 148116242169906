import "./App.css";
import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./Components/HOC/ProtectedRoute";
import FallbackUi from "./Components/Fallback/FallbackUi";
import { useNetworkCheck } from "./Components/Hooks/useNetworkCheck";
import NetworkLoss from "./Pages/Network-loss/NetworkLoss";
// import Login from "./Pages/Auth/Login";

const Login = lazy(() => import("./Pages/Auth/Login"));
const Myaccount = lazy(() => import("./Pages/Account/Myaccount"));
const Calendar = lazy(() => import("./Pages/Calendar/Calendar"));
const Class = lazy(() => import("./Components/ManualZoomMeeting/Class"));
// const Event = lazy(() => import ("./Pages/Events/Event"))
const EventOfDay = lazy(() => import("./Pages/Events/EventOfDay"));

function App() {
  // const { isOnline, handleRetry } = useOnlineStatus();
  const { isOnline } = useNetworkCheck();

  if (!isOnline) {
    return <NetworkLoss />
  }
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/:encodedEmail" element={<Login />} />
        <Route
          path="/Myaccount"
          element={
            <Suspense fallback={<FallbackUi />}>
              <ProtectedRoute>
                <Myaccount />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/Calendar"
          element={
            <Suspense fallback={<FallbackUi />}>
              <ProtectedRoute>
                <Calendar />
              </ProtectedRoute>
            </Suspense>
          }
        />


        <Route
          path="/class"
          element={
            <Suspense fallback={<FallbackUi />}>
              <ProtectedRoute>
                <Class />
              </ProtectedRoute>
            </Suspense>
          }
        />
        {/* <Route
          path="/event"
          element={
            <Suspense fallback={<FallbackUi />} >
            <ProtectedRoute>
              <Event />
            </ProtectedRoute>
            </Suspense>
          }
        /> */}
        <Route
          path="/allevents"
          element={
            <Suspense fallback={<FallbackUi />}>
              <ProtectedRoute>
                <EventOfDay />
              </ProtectedRoute>
            </Suspense>
          }
        />
        {/* <Route path="/test" element={<FallbackUi />} /> */}
      </Routes>
    </>
  );
}

export default App;