import { configureStore } from "@reduxjs/toolkit";
import { meetingReducer } from "./reducers/meeting";
import { webinarReducer } from "./reducers/webinar";

const initialState = {
    webinar: {
        signature: null,
        webinarId: null,
        name: null,
        email: null,
    },
    meeting: {
        signature: null,
        meetingId: null,
        meetingPasscode: null,
        name: "",
        email: null,
    },
};

const store = configureStore({
    reducer: {
        meeting: meetingReducer,
        webinar: webinarReducer,
    },
    preloadedState: initialState,
});

export default store;
