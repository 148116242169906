import React, { useEffect, useState } from "react";
import useAuth from "../Hooks/useAuth";
import { Navigate } from "react-router-dom";
import { Backdrop, Box, CircularProgress } from "@mui/material";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  const [loopen, setLoOpen] = useState(false);

  useEffect(() => {
    if (loading) {
      setLoOpen(true);
    } else {
      setLoOpen(false);
    }
  }, [loading]);

//   console.log("protected route hit", isAuthenticated);
  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loopen}
      >
        <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      </Backdrop>
    );
  }

  return isAuthenticated ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
