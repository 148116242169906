import { Backdrop, Box, CircularProgress } from '@mui/material'
import React from 'react'

const FallbackUi = () => {
  return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      </Backdrop>
  )
}

export default FallbackUi
